<template>
    <div class="viewDetails">
      <div class="breadcrumb-box">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px;margin-top: 10px;">
          <el-breadcrumb-item>教务管理</el-breadcrumb-item>
          <el-breadcrumb-item>任务中心</el-breadcrumb-item>
          <el-breadcrumb-item>任务详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="primary" @click="goBack">返回</el-button>
      </div>
      <el-scrollbar class="detailBox">
        <div class="details-header" ref="detailsHeader" :style="unfoldHeight ? 'max-height: 500px;overflow-y: hidden' : ''">
          <div class="header-title">
            <span class="text-overflow">{{headerDetail.task_title}}</span>
          </div>
          <div class="header-time">
            <div class="time-left">
              <span>发布日期： {{headerDetail.create_time}}</span>
              <span>剩余日期：<strong>{{dayCount}}天{{hourCount}}小时{{minCount}}分钟{{secCount}}秒</strong></span>
              <span v-if="headerDetail.task_type_name">需求类型： {{headerDetail.task_type_name}}</span>
<!--              <span>参与人数： {{headerDetail.num}}</span>-->
              <span>指定班级：<span style="padding-left: 0" v-for="item in executeClass">{{item.name}}</span></span>
            </div>
            <div class="status-btn">
              <!--                headerDetail-->
              <el-button v-if="headerDetail.state === '进行中'" class="btn1" type="primary">进行中</el-button>
              <el-button v-if="headerDetail.state === '已截止'" class="btn2">已截止</el-button>
            </div>
          </div>
          <div class="demand-desc" v-if="taskType === 1 && JSON.stringify(headerDetail.task_content) !== '<p><br></p>'">
            <div class="desc-title">
              <div class="title-block"></div>
              <span>需求描述</span>
            </div>
            <div class="title-p" v-html="headerDetail.task_content"></div>
          </div>
          <div class="demand-desc" v-if="taskType === 3 && headerDetail.trainInfo && headerDetail.trainInfo.introduction">
            <div class="desc-title">
              <div class="title-block"></div>
              <span>{{headerDetail.trainInfo.introduction.name}}</span>
            </div>
            <div class="title-p"><p>{{headerDetail.trainInfo.introduction.content}}</p></div>
          </div>
          <div class="demand-desc" v-if="taskType === 4 && headerDetail.script">
            <div class="desc-title">
              <div class="title-block"></div>
              <span>脚本简介</span>
            </div>
            <div class="title-p"><p>{{headerDetail.script.script_introduction}}</p></div>
          </div>
          <div class="demand-desc" v-if="taskType === 4 && headerDetail.script">
            <div class="desc-title" v-if="headerDetail.script.script_content !== '<p><br></p>'">
              <div class="title-block"></div>
              <span>脚本内容</span>
            </div>
            <div class="title-p" v-html="headerDetail.script.script_content" v-if="headerDetail.script.script_content !== '<p><br></p>'"></div>
            <div class="script-table" v-if="headerDetail.script.camera_lens && headerDetail.script.camera_lens.length">
              <div class="script-table-row">
                <div class="script-table-column">镜头</div>
                <div class="script-table-column">景别</div>
                <div class="script-table-column">拍摄方向</div>
                <div class="script-table-column">运镜</div>
                <div class="script-table-column">拍摄内容</div>
                <div class="script-table-column">音乐/字幕</div>
                <div class="script-table-column">时间</div>
              </div>
              <div class="script-table-row" v-for="item in headerDetail.script.camera_lens">
                <div class="script-table-column"><span class="text-overflow">{{item.number}}</span></div>
                <div class="script-table-column"><span class="text-overflow">{{item.farewell}}</span></div>
                <div class="script-table-column"><span class="text-overflow">{{item.direction}}</span></div>
                <div class="script-table-column"><span class="text-overflow">{{item.mirror_movement}}</span></div>
                <div class="script-table-column"><span class="text-overflow">{{item.content}}</span></div>
                <div class="script-table-column"><span class="text-overflow">{{item.music_or_subtitle}}</span></div>
                <div class="script-table-column"><span class="text-overflow">{{item.time}}</span></div>
              </div>
            </div>
          </div>
          <div style="display: flex;flex-direction: column" v-if="taskType === 3 && headerDetail.trainInfo">
            <div class="accessory" v-if="headerDetail.trainInfo.target">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>{{headerDetail.trainInfo.target.name}}</span>
              </div>
              <div class="accessory-name">
                {{headerDetail.trainInfo.target.content}}
              </div>
            </div>
            <div class="accessory" v-if="headerDetail.trainInfo.requirement">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>{{headerDetail.trainInfo.requirement.name}}</span>
              </div>
              <div class="accessory-name">
                {{headerDetail.trainInfo.requirement.content}}
              </div>
            </div>
            <div class="accessory" v-if="headerDetail.trainInfo.content">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>{{headerDetail.trainInfo.content.name}}</span>
              </div>
              <div class="accessory-name" v-html="headerDetail.trainInfo.content.content"></div>
            </div>
            <div class="accessory" v-for="item in headerDetail.trainInfo.determined_column">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>{{item.name}}</span>
              </div>
              <div class="accessory-name">
                {{item.content}}
              </div>
            </div>
            <div class="accessory" v-if="headerDetail.trainInfo.script">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>关联脚本</span>
              </div>
              <div class="script-box">
                <div class="script-item" @click="scriptDialog">
                  <i class="iconfont">&#xe66d;</i>
                  <span>{{headerDetail.trainInfo.script.name}}</span>
                </div>
              </div>
            </div>
            <el-dialog
                custom-class="script-dialog"
                :visible.sync="scriptVisible"
                width="66%"
                :before-close="scriptHandleClose">
              <div class="script-dialog-box" v-if="headerDetail.trainInfo.script">
                <div class="script-dialog-header">
                  <div class="header-left">
                    <img :src="headerDetail.trainInfo.script.cover_url" alt="">
                  </div>
                  <div class="header-right">
                    <span>脚本名称：{{headerDetail.trainInfo.script.name}}</span>
                    <span>脚本分类：{{headerDetail.trainInfo.script.classification_name}}</span>
                    <span>脚本简介：{{headerDetail.trainInfo.script.script_introduction ? headerDetail.trainInfo.script.script_introduction : '暂无简介'}}</span>
                  </div>
                </div>
                <div class="script-dialog-content" v-if="headerDetail.trainInfo.script.script_content !== '<p><br></p>' && headerDetail.trainInfo.script.script_content !== null">
                  <div v-html="headerDetail.trainInfo.script.script_content"></div>
<!--                  <span v-else>暂无内容</span>-->
                  <div class="content-title">
                    <div class="title-dot"></div>
                    <span>脚本内容</span>
                    <div class="title-dot"></div>
                  </div>
                </div>
                <el-scrollbar class="script-dialog-table">
                  <div class="dialog-table-row">
                    <div class="dialog-table-column">镜头</div>
                    <div class="dialog-table-column">景别</div>
                    <div class="dialog-table-column">拍摄方向</div>
                    <div class="dialog-table-column">运镜</div>
                    <div class="dialog-table-column">拍摄内容</div>
                    <div class="dialog-table-column">音乐/字幕</div>
                    <div class="dialog-table-column">时间</div>
                  </div>
                  <div class="dialog-table-row" v-for="item in headerDetail.trainInfo.script.camera_lens">
                    <div class="dialog-table-column"><span class="text-overflow">{{item.number}}</span></div>
                    <div class="dialog-table-column"><span class="text-overflow">{{item.farewell}}</span></div>
                    <div class="dialog-table-column"><span class="text-overflow">{{item.direction}}</span></div>
                    <div class="dialog-table-column"><span class="text-overflow">{{item.mirror_movement}}</span></div>
                    <div class="dialog-table-column"><span class="text-overflow">{{item.content}}</span></div>
                    <div class="dialog-table-column"><span class="text-overflow">{{item.music_or_subtitle}}</span></div>
                    <div class="dialog-table-column"><span class="text-overflow">{{item.time}}</span></div>
                  </div>
                </el-scrollbar>
                <div class="script-dialog-btn">
                  <el-button type="primary" class="blue-btn" @click="scriptVisible = false">关闭</el-button>
                </div>
              </div>
            </el-dialog>
            <div class="accessory" v-if="headerDetail.trainInfo.source_material">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>素材下载</span>
              </div>
              <div class="material-box">
                <div class="material-item" v-for="item in headerDetail.trainInfo.source_material">
                  <div class="item-left">
                    <i class="iconfont">&#xe66d;</i>
                    <span>{{item.name}}</span>
                  </div>
                  <div class="item-right">
                    <a :href="item.url" target="_blank"><i class="iconfont">&#xe69a;</i></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="accessory" v-if="headerDetail.trainInfo.evaluate">
              <div class="desc-title">
                <div class="title-block"></div>
                <span>实训评价</span>
              </div>
              <div class="evaluate-table">
                <div class="evaluate-row">
                  <div class="evaluate-column">序号</div>
                  <div class="evaluate-column">内容</div>
                  <div class="evaluate-column">要求</div>
                  <div class="evaluate-column">分值</div>
                </div>
                <div class="evaluate-row" v-for="item in headerDetail.trainInfo.evaluate">
                  <div class="evaluate-column">{{item.number}}</div>
                  <div class="evaluate-column">{{item.content}}</div>
                  <div class="evaluate-column">{{item.requirement}}</div>
                  <div class="evaluate-column">{{item.score}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="unfold-content" v-if="unfoldHeightValue > 500">
          <span @click="unfoldContent">{{unfoldHeight ? '展开内容 >' : '收起内容 >'}}</span>
        </div>
        <div class="cut-off" style="height: 20px;width: 100%;background: #F3F3F6"></div>
        <div class="submissions">
          <div class="submissions-title">
            提交内容
          </div>
          <div class="class-select">
            <span>班级筛选</span>
            <el-select class="el-select" v-model="value" placeholder="请选择" @change="classSelect">
              <el-option
                  v-for="(item,index) in executeClass"
                  :key="item.class_id"
                  :label="item.name"
                  :value="item.class_id">
              </el-option>
            </el-select>
          </div>
          <el-table class="tableList" :data="selectDetailList" style="width: 100%; flex: 1; " size="medium" :header-cell-style="{fontWeight: 'normal', color: '#666666', background: '#F5F5F5',fontSize: '14px', height: '46px'}" :cell-style="{fontSize: '14px',color: '#666666', height:'70px'}">
            <el-table-column type="index" label="序号" align="center" width="80"></el-table-column>
            <el-table-column prop="username" label="姓名" align="center" width="180">
              <template slot-scope="scope">
                <span class="text-overflow">{{scope.row.username}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="班级" align="center" width="180"></el-table-column>
            <el-table-column label="教师评语" align="left" width="500">
              <template slot-scope="scope">
                <span v-if="scope.row.teacher_comment" class="text-overflow">{{scope.row.teacher_comment.comment}}</span>
                <span v-else>暂无评语</span>
              </template>
            </el-table-column>
            <el-table-column label="服务打分" align="left">
              <template slot-scope="scope">
                <el-rate disabled v-if="scope.row.teacher_comment" v-model="scope.row.teacher_comment.score"></el-rate>
                <span v-else>暂无打分</span>
              </template>
            </el-table-column>
            <el-table-column label="审批结果" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.teacher_comment">
                  <i v-if="scope.row.teacher_comment.is_qualified === 1" class="iconfont is-qualified">&#xe692;</i>
                  <i v-if="scope.row.teacher_comment.is_qualified === 2" class="iconfont no-qualified">&#xe690;</i>
                </div>
                <span v-else>暂无审批</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span @click="viewAnnex(scope.row)" style="color: #4D5AFF;cursor: pointer;">查看详情</span>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
              title="评分"
              :visible.sync="isQualified"
              width="30%">
            <span>确定该作品为{{isqualifiedTxt}}作品？确定后将无法修改</span>
            <span slot="footer" class="dialog-footer">
                            <el-button @click="isQualified = false">取 消</el-button>
                            <el-button type="primary" @click="confirmHg">确 定</el-button>
                            </span>
          </el-dialog>
          <el-pagination
              style="padding: 30px 0"
              class="pages-center"
              :current-page="studentPages.currentPageNum"
              :page-size="studentPages.eachPageNum"
              layout="prev, pager, next, jumper"
              :total="studentPages.total"
              @current-change="studentCurrentChange">
          </el-pagination>
        </div>
      </el-scrollbar>
    </div>
</template>

<script>
    import {mapActions,mapMutations} from 'vuex'
    export default {
        name:'viewDetails',
        data(){
            return {
                isqualifiedTxt:'',
                isQualified:false,
                decide:'', //1合格 2不合格
                task_works_id:1, // 判定的提交任务id
                isShow:false, //是否截止
                cutTime:'',  //截止时间
                dayCount:'', //天数
                hourCount:'', //小时
                minCount:'', //分钟
                secCount:'', //秒
                timer:'',
                headerDetail:[], //头部详情
                detailList:[], //详情列表
                selectDetailList:[],
                value:'',
                executeClass:[],
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                classIfl:[],
                className:'',
                taskType:1,
                scriptVisible:false,
                unfoldHeight:false,
                unfoldHeightValue:'',
            }
        },
        created() {
            this.getHeaderDetail();
            this.getDetailList();
        },
        mounted() {
          this.getClassify();
          setTimeout(()=>{
            this.unfoldHeightValue = this.$refs.detailsHeader.offsetHeight;
            if(this.unfoldHeightValue > 500){
              this.unfoldHeight = true
            }
          },500)
        },
      methods:{
            ...mapMutations(['setLibrary', 'setLocalAnnexList','setteacherRemarkObj', 'setStudentContent']),
            // 展开关闭内容
            unfoldContent(){
              this.unfoldHeight = !this.unfoldHeight;
            },
            // 获取分类列表
            getClassify() {
              let params = {};
              this.$httpteacher.axiosGetBy(this.$api.classification,params, (res)=>{
                if(res.code === 200){
                  this.classIfl = res.data.data;
                  this.classIfl.forEach(item=>{
                    if(item.id === this.headerDetail.task_type_id){
                      this.className = item.name
                    }
                  })
                }
              })
            },
            //班级筛选
            classSelect(value){
                this.getDetailList()
            },
            // 合格
            qualified(row){
                this.decide = 1;
                this.task_works_id = row.task_works_id;
                this.isqualifiedTxt = '合格',
                this.isQualified = true;
            },
            // 不合格
            disqualification(row){
                this.task_works_id = row.task_works_id;
                this.decide = 2;
                this.isqualifiedTxt = '不合格',
                this.isQualified = true;
            },
            // 提交是否合格
            confirmHg(){
                if(this.decide == 1){
                    let param = {
                        task_works_id:this.task_works_id,
                        state:2
                    };
                    this.$httpteacher.axiosGetBy(this.$api.worksState,param,(res)=>{
                        if(res.code === 200){
                            this.$message({
                                type: "success",
                                message: "修改成功"
                            });
                            this.getDetailList();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    })
                } else if(this.decide == 2){
                    let params = {
                        task_works_id:this.task_works_id,
                        state:1
                    };
                    this.$httpteacher.axiosGetBy(this.$api.worksState,params,(res)=>{
                        if(res.code === 200){
                            this.$message({
                                type: "success",
                                message: "修改成功"
                            });
                            this.getDetailList();
                        } else {
                            this.$message.warning(res.msg);
                        }
                    })
                }
                this.isQualified = false;
            },
            //时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
            dateFormat:function(time) {
                var date=new Date(time*1000);
                var year=date.getFullYear();
                var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
                var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
                var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
                // 拼接
                return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
            },
            dateFormatTwo:function(time) {
                var date=new Date(time);
                var year=date.getFullYear();
                var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
                var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
                var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
                // 拼接
                return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
            },
            // 头部详情
            getHeaderDetail(){
                // console.log(this.$route.query.id)
                const id = this.$route.query.id;
                let parms = {
                    task_id:id
                };
                this.$httpteacher.axiosGetBy(this.$api.taskDetail,parms,(res)=>{
                  console.log(res)
                    if(res.code === 200){
                        this.headerDetail = res.info;
                        if(res.info.task_type){
                          this.taskType = res.info.task_type;
                        }
                        this.executeClass = res.info.class_info;
                        clearInterval(this.timer)
                        this.timer = setInterval(()=>{
                            let cutTime = this.dateFormat(this.headerDetail.task_end_time);
                            let date = Date.parse(new Date());
                            let nowTime = this.dateFormatTwo(date)
                            // console.log(cutTime,nowTime)
                            let time1 = Date.parse(cutTime);
                            let time2 = Date.parse(nowTime);
                            // console.log(180,time2-time1)
                            if(time2-time1 >= 0){
                                clearInterval(this.timer)
                                this.isShow = true;
                                this.dayCount = 0;
                                this.hourCount = 0;
                                this.minCount = 0;
                                this.secCount = 0;
                            } else {
                              this.dayCount = Math.floor((Math.abs(time2 - time1))/1000/60/60/24);
                              this.hourCount = Math.floor((Math.abs(time2 - time1))/1000/60/60%24);
                              this.minCount = Math.floor((Math.abs(time2 - time1))/1000/60%60);
                              this.secCount = Math.floor((Math.abs(time2 - time1))/1000%60)
                            }
                            // console.log(142,this.dayCount,this.hourCount,this.minCount,this.secCount)
                            // if(this.dayCount <= '0' && this.hourCount <= '0' && this.minCount <= '0' && this.secCount <= '0'){
                            //     clearInterval(this.timer)
                            //     this.isShow = true
                            // }
                        },1000)


                    }
                })
            },
            // 获取详情列表
            getDetailList(){
                const id = this.$route.query.id;
                let parms = {
                    task_id:id
                };
                if(this.value){
                    parms.class_id = this.value
                }
                this.$httpteacher.axiosGetBy(this.$api.worksList,parms,(res)=>{
                    if(res.code === 200){
                        this.detailList = res.list;
                        this.selectDetailList = res.list;
                        this.studentPages.total = res.total;
                    }
                })
            },
            studentCurrentChange(val){
                this.studentPages.currentPageNum = val;
                this.getDetailList();
            },
            //附件预览
            viewAnnex(row) {
                this.setLibrary(row.task_works_library);
                this.setLocalAnnexList(row.task_works_file);
                this.setteacherRemarkObj(row.teacher_comment);
                this.setStudentContent(row.task_works_content);
                if (row.task_works_state === '' || row.task_works_state === null) {
                    row.task_works_state = 0;
                }
                this.$router.push({
                    path: '/teacher/taskcenter/annexdetails',
                    query: {
                        sName: row.username,//学生姓名
                        cName: row.name,//班级名称
                        tState: row.task_works_state,//任务状态：合格/不合格/暂未操作
                        taskType:this.taskType, //任务类型 1普通 3实训 4脚本
                        taskId:this.$route.query.id,
                        userId: row.user_id,
                    }
                })
            },
            //下载附件
            // materialDown(url) {
            //     window.location.href = url;
            // },
            goBack(){
              this.$router.go(-1)
            },
            // 打开脚本窗口
            scriptDialog(){
              this.scriptVisible = true;

            },
            scriptHandleClose(){

            }
        }
    }
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
    .buttonText{
        color: rgb(77, 90, 255);
    }
    /*::v-deep.el-tab-pane{*/
    /*    height: 100%;*/
    /*}*/
    .viewDetails{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);
        background: #fff;
        .breadcrumb-box{
          background: #F3F3F6;
          display: flex;
          justify-content: space-between;
          ::v-deep.el-button--primary {
            height: 36px;
            line-height: 0;
            color: #FFF;
            background-color:#0824ce;
            border-color: #0824ce;
          }
          ::v-deep.el-button--primary:focus, .el-button--primary:hover {
            background: #495df1;
            border-color: #495df1;
            color: #FFF;
          }
        }
        .details-header{
            padding: 10px;
            display: flex;
            flex-direction: column;
            background: #fff;
            .header-title{
              color: #333;
              font-size: 16px;
              padding: 20px 26px;
              border-bottom: 1px solid #E5E5E5;
            }
            .header-time{
              display: flex;
              justify-content: space-between;
              border-bottom: 1px solid #E5E5E5;
              padding-bottom: 20px;
              .time-left{
                display: flex;
                flex-direction: column;
                span{
                  padding-left: 34px;
                  font-size: 14px;
                  color: #666666;
                  line-height: 1;
                  margin-top: 22px;
                  span{
                    &:after{
                      content:'、'
                    }
                    &:last-child{
                      &:after{
                        content:''
                      }
                    }
                  }
                  &:nth-child(2){
                    strong{
                      color: #FF0000;
                      font-weight: 400;
                    }
                  }
                }
              }
              .status-btn{
                display: flex;
                align-items: center;
                margin-right: 20px;
                .btn1{
                    color: #FFF;
                    background-color:#9ACC5B;
                    border-color: #9ACC5B;
                }
                .btn2{
                  background: #DCDCDC;
                  color: #333333;
                  border-color: #DCDCDC;
                }
              }
            }
            .demand-desc{
              border-bottom: 1px solid #EEEEEE;
              //overflow-y: hidden;
              padding-left: 35px;
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              //::v-deep .el-scrollbar__wrap {
              //  overflow-x: hidden;
              //}
              //::v-deep.el-scrollbar__thumb{
              //  display: none;
              //}
              .desc-title{
                display: flex;
                align-items: center;
                .title-block{
                  width: 3px;
                  height: 10px;
                  background: #00B47F;
                  margin-right: 6px;
                }
                span{
                  font-size: 16px;
                }
              }
              .title-p{
                padding: 10px 0;
              }
              .script-table{
                margin-bottom: 30px;
                display: flex;
                flex-direction: column;
                margin-right: 150px;
                .script-table-row{
                  display: flex;
                  &:nth-child(1){
                    line-height: 1;
                    .script-table-column{
                      padding: 18px 0;
                    }
                  }
                  .script-table-column{
                    display: flex;
                    flex: 1;
                    width: 1%;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0;
                    margin-right: 3px;
                  }
                  &:nth-child(odd){
                    .script-table-column{
                      background: #D3DDFF;
                    }
                  }
                  &:nth-child(even){
                    .script-table-column{
                      background: #F0F3FF;
                    }
                  }
                }
              }
            }
          .accessory{
            margin-top: 16px;
            padding-left: 35px;
            padding-bottom: 20px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid #EEEEEE;
            .desc-title{
              display: flex;
              align-items: center;
              .title-block{
                width: 3px;
                height: 10px;
                background: #00B47F;
                margin-right: 6px;
              }
              span{
                font-size: 16px;
              }
            }
            .accessory-name{
              font-size: 14px;
              margin-top: 20px;
            }
            .script-box{
              .script-item{
                display: flex;
                align-items: center;
                line-height: 1;
                margin-top: 20px;
                cursor: pointer;
                i{
                  font-size: 16px;
                  color: #409EFF;
                  margin-right: 10px;
                }
                span{
                  font-size: 14px;
                }
              }
            }
            .material-box{
              display: flex;
              flex-wrap: wrap;
              padding-top: 20px;
              .material-item{
                margin-top: 10px;
                display: flex;
                align-items: center;
                width: 33%;
                justify-content: space-around;
                .item-left{
                  display: flex;
                  align-items: center;
                  i{
                    font-size: 18px;
                    color: #409EFF;
                    margin-right: 7px;
                    cursor: pointer;
                  }
                  span{
                    font-size: 18px;
                  }
                }
                .item-right{
                  i{
                    font-size: 22px;
                    color: #409EFF;
                  }
                }
              }
            }
            .evaluate-table{
              margin-top: 30px;
              margin-left: 80px;
              width: 70%;
              display: flex;
              flex-direction: column;
              .evaluate-row{
                display: flex;
                &:nth-child(even){
                  .evaluate-column{
                    background: #F0F3FF;
                  }
                }
                &:nth-child(odd){
                  .evaluate-column{
                    background: #D3DDFF;
                  }
                }
                &:nth-child(1){
                  height: 50px;
                }
                .evaluate-column{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 4px;
                  padding: 16px 0;
                  &:nth-child(1){
                    width: 64px;
                  }
                  &:nth-child(2){
                    width: 330px;
                  }
                  &:nth-child(3){
                    display: flex;
                    flex: 1;
                    width: 1%;
                  }
                  &:nth-child(4){
                    width: 80px;
                  }
                }
              }
            }
          }
        }
      .unfold-content{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #2338E6;
        font-size: 14px;
        line-height: 1;
        padding: 20px 0 30px 0;
        span{
          cursor: pointer;
        }
      }
        .submissions{
            background: #ffffff;
            overflow: hidden;
            .submissions-title{
                margin: 24px;
                color: #333333;
                font-size: 16px;
                font-weight:400;
            }
            .class-select{
                font-size: 14px;
                span{
                    display: inline-block;
                    color: #333333;
                    margin-left: 24px;
                    margin-bottom: 30px;
                }
                ::v-deep .el-select{
                    height: 28px;
                    margin-left: 10px;
                    width: 135px;
                }

            }
            .tableList{
                span{
                    color: #666666;
                    font-size:14px;
                    font-weight: 400;
                }
            }
        }
    }
    .dialog-footer {
        .el-button {
            padding: 8px 34px;
        }
        .el-button--primary {
            background-color: #2338E6;
            border: 1px solid #2338E6;
            &:hover {
                background-color: #1b2dbf;
                border: 1px solid #1b2dbf;
            }
        }
    }
    .annex-list {
        .annex-item {
            display: flex;
            margin-bottom: 15px;
            .annex-name {
                float: left;
                display: block;
                max-width: 300px;
                margin-right: 30px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                border-bottom:1px solid transparent;
                color: #000;
                &:hover {
                    border-bottom:1px solid #2338E6;
                    color: #2338E6;
                }
            }
            .download {
                color: #2338E6;
                width: 50px;
                margin-right: 20px;
                cursor: pointer;
                border-bottom:1px solid transparent;
                &:hover {
                    border-bottom:1px solid #2338E6;
                }
            }
        }
    }
    .annex-list-dialog {
        ::v-deep .el-dialog__header {
            border-bottom: 1px solid #EAEAEA;
            ::v-deep .el-dialog__title {
                font-size: 14px;
            }
        }
        ::v-deep .el-dialog__body {
            padding: 20px 30px;
        }
        ::v-deep .el-dialog__footer {
            text-align: center;
        }
        .annex-content {
            height: 257px;
            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
                .el-scrollbar__view {
                    /*height: 100%;*/
                }
            }
        }
        .annex-user {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            .user {
                color: #333;
                margin-bottom: 10px;
            }
            .time {
                color: #666666;
                font-size: 12px;
            }
        }
    }
    .detailBox{
      display: flex;
      flex-direction: column;
      height: 100%;
      //background: #fff;
      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    ::v-deep.script-dialog{
      border-radius: 6px;
      .el-dialog__header{
        display: none;
      }
      .el-dialog__body{
        padding: 30px;
      }
      .script-dialog-box{
        display: flex;
        flex-direction: column;
        .script-dialog-header{
          display: flex;
          .header-left{
            img{
              max-height: 226px;
              max-width: 390px;
              border-radius: 10px;
            }
          }
          .header-right{
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            flex: 1;
            width: 1%;
            span{
              font-weight: 400;
              &:nth-child(1){
                color: #333333;
                font-size: 18px;
                margin-top: 10px;
              }
              &:nth-child(2){
                color: #666666;
                font-size: 16px;
                margin-top: 30px;
              }
              &:nth-child(3){
                color: #666666;
                font-size: 16px;
                margin-top: 8px;
                line-height: 30px;
              }
            }
          }
        }
        .script-dialog-content{
          display: flex;
          padding: 40px 20px;
          border: 2px solid #584EEE;
          border-radius: 20px;
          margin-top: 48px;
          position: relative;
          .content-title{
            padding: 0 22px;
            display: flex;
            height: 32px;
            align-items: center;
            background: #584EEE;
            border-radius: 16px;
            position: absolute;
            left: 42px;
            top: -16px;
            span{
              font-size: 16px;
              color: #F7F7F7;
              line-height: 1;
              margin: 0 20px;
            }
            .title-dot{
              width: 6px;
              height: 6px;
              background: #fff;
              border-radius: 50%;
            }
          }
        }
        .script-dialog-table{
          display: flex;
          flex-direction: column;
          margin-top: 30px;
          height: 265px;
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
          .dialog-table-row{
            display: flex;
            margin-bottom: 3px;
            &:nth-child(odd){
              background: #DEDCFD;
            }
            &:nth-child(even){
              background: #F8F7FF;
            }
            &:nth-child(1){
              .dialog-table-column{
                font-size: 14px;
                color: #222222;
                font-weight: 400;
              }
            }
            .dialog-table-column{
              display: flex;
              flex: 1;
              width: 1%;
              justify-content: center;
              align-items: center;
              height: 50px;
            }
          }
        }
        .script-dialog-btn{
          display: flex;
          justify-content: center;
          margin-top: 68px;
        }
      }
    }
::v-deep.el-rate .el-rate__item{
  .el-rate__icon{
    font-size: 17px;
    margin-right: 0;
  }
}
.is-qualified, .no-qualified{
  font-size: 38px;
  line-height: 1;
  &.is-qualified{
    color: #20C997;
  }
  &.no-qualified{
    color: #FF0000;
  }
}
</style>